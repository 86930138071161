import angular from 'angular';
import './mini-dialog-manager.scss';
import component from './mini-dialog-manager.view';

const module = angular.module('app.shell.modules.mini-dialog-manager', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider
            .state('mini-dialog-manager', {
                url: '/mini-dialog-manager',
                title: 'Pakket-p SHELL mini dialogue manager',
                template: `
                    <shell-mini-dialog-manager></shell-mini-dialog-manager>
                `,
                security: permissionService => permissionService.canManageMiniDialogs,
            });
    })
    .component('shellMiniDialogManager', component);

export default module.name;
