import angular from 'angular';
import component from './table-of-content.view';
import './table-of-content.scss';
import reactComponent from '../../../common/react-component';
import ExportTOCButton from './components/ExportTOCButton';

const module = angular.module('app.shell.modules.table-of-content', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider
            .state('table-of-content', {
                url: '/table-of-content',
                template: '<table-of-contents></table-of-contents>',
                security: permissionService => permissionService.canManageTableOfContent,
            });
    })
    .component('tableOfContents', component)
    .component('exportTocButton', reactComponent(ExportTOCButton, ['disabled', 'selectedModule', 'baseLinkUrl']));

export default module.name;
