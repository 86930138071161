import React, {
    useEffect,
    useReducer,
    useState,
} from 'react';
import { func, string } from 'prop-types';

import './ImportTab.scss';

import Uploader from 'rc-upload';

import api from '../../../../services/api';

import ImportErrorView from './components/ImportErrorView';
import Alert from '../../../../components/Alert';
import GlyphIcon from '../../../../components/GlyphIcon';
import Button from '../../../../components/Button';
import Panel from '../../../../components/Panel';
import ImportConfirmation from './components/ImportConfirmation';
import MediaLinkActionNames from '../../media-links-action-names';

const MODULE_CHANGED = 'MODULE_CHANGED';

const CSV_DATA_RECEIVED = 'CSV_DATA_RECEIVED';
const CSV_PARSING_FAILED = 'CSV_PARSING_FAILED';
const CSV_PARSING_FAILED_UNEXPECTED = 'CSV_PARSING_FAILED_UNEXPECTED';

const IMPORT_VALIDATED = 'IMPORT_VALIDATED';
const IMPORT_VALIDATION_FAILED = 'IMPORT_VALIDATION_FAILED';
const IMPORT_VALIDATION_FAILED_UNEXPECTED = 'IMPORT_VALIDATION_FAILED_UNEXPECTED';

const IMPORT_CANCELLED = 'IMPORT_CANCELLED';

const IMPORT_CONFIRMED = 'IMPORT_CONFIRMED';
const IMPORT_CONFIRMATION_OUTDATED = 'IMPORT_CONFIRMATION_OUTDATED';
const IMPORT_CONFIRMATION_VALIDATION_FAILED = 'IMPORT_CONFIRMATION_VALIDATION_FAILED';
const IMPORT_CONFIRMATION_FAILED = 'IMPORT_CONFIRMATION_FAILED';

function reducer(state, action) {
    switch (action && action.type) {
        case MODULE_CHANGED:
        case IMPORT_CANCELLED: {
            return {};
        }
        case CSV_DATA_RECEIVED: {
            const { payload: csvData } = action;
            return { csvData };
        }
        case CSV_PARSING_FAILED:
            return {
                csvParsingError: true,
            };
        case IMPORT_VALIDATED:
        case IMPORT_CONFIRMATION_OUTDATED: {
            const { csvData } = state;
            const { payload: unconfirmedRows } = action;

            return {
                csvData,
                outdated: action.type === IMPORT_CONFIRMATION_OUTDATED,
                unconfirmedRows,
            };
        }
        case IMPORT_VALIDATION_FAILED:
        case IMPORT_CONFIRMATION_VALIDATION_FAILED: {
            const { csvData } = state;
            const { details } = action.payload;

            const validationErrors = details.map(detail => ({
                ...detail,
                name: detail.index !== undefined ? csvData[detail.index].Name : undefined,
            }));

            return {
                csvData,
                validationErrors,
            };
        }
        case CSV_PARSING_FAILED_UNEXPECTED:
        case IMPORT_VALIDATION_FAILED_UNEXPECTED:
        case IMPORT_CONFIRMATION_FAILED: {
            const { response } = action.payload;

            const { status, statusText } = response;
            return { unExpectedStatusError: { status, statusText } };
        }
        case IMPORT_CONFIRMED: {
            return {
                importConfirmed: true,
            };
        }
        default: throw new Error(`Unknown action given (${action.type})`);
    }
}

function ImportTab({ moduleId, ngDispatch }) {
    const [state, dispatch] = useReducer(reducer, {});
    const [groupBy, setGroupBy] = useState('change');

    useEffect(() => {
        dispatch({ type: MODULE_CHANGED });
    }, [moduleId]);

    const parseCSV = async ({
        file,
        fileName,
        onSuccess,
        onError,
    }) => {
        try {
            const formData = new FormData();
            formData.append('file', file, fileName);

            const { data: { data: csvData } } = await api.post('shell/parse-csv', formData);

            dispatch({
                type: CSV_DATA_RECEIVED,
                payload: csvData,
            });

            onSuccess(csvData, file);
        } catch (err) {
            onError(err);
        }
    };

    const onCSVParsingSuccess = async csvData => {
        try {
            const { data: { rows } } = await api.post(`shell/modules/${moduleId}/medialinks/bulk`, { rows: csvData });

            dispatch({
                type: IMPORT_VALIDATED,
                payload: rows,
            });
        } catch (e) {
            const { response } = e;
            if (!response) throw e;

            const { status } = response;

            if (status === 400) {
                const badRequestResponse = response.data;

                dispatch({
                    type: IMPORT_VALIDATION_FAILED,
                    payload: badRequestResponse,
                });

                return;
            }

            dispatch({
                type: IMPORT_VALIDATION_FAILED_UNEXPECTED,
                payload: e,
            });
        }
    };

    const onCSVParsingError = err => {
        const { response } = err;
        if (!response) throw err;

        dispatch(response.status === 400
            ? { type: CSV_PARSING_FAILED }
            : { type: CSV_PARSING_FAILED_UNEXPECTED, payload: err });
    };

    const confirmImport = async () => {
        try {
            const res = await api.post(`shell/modules/${moduleId}/medialinks/bulk`,
                { rows: state.unconfirmedRows.filter(x => x.action !== undefined), confirm: true });

            if (res.status === 204) {
                dispatch({ type: IMPORT_CONFIRMED });
                ngDispatch({ type: MediaLinkActionNames.MEDIALINKS_IMPORTED });
            } else {
                const { rows } = res.data;
                dispatch({ type: IMPORT_CONFIRMATION_OUTDATED, payload: rows });
            }
        } catch (e) {
            const { response } = e;
            if (!response) throw e;

            if (response.status === 400) {
                const badRequest = response.data;

                dispatch({
                    type: IMPORT_CONFIRMATION_VALIDATION_FAILED,
                    payload: badRequest,
                });

                return;
            }

            dispatch({
                type: IMPORT_CONFIRMATION_FAILED,
                payload: e,
            });
        }
    };

    return (
        <div className="row">
            <Panel>
                <Panel.Heading title="Import csv" />
                <Panel.Body>
                    <div className="mb-15">
                        <Uploader
                            accept=".csv"
                            onSuccess={onCSVParsingSuccess}
                            onError={onCSVParsingError}
                            customRequest={parseCSV}
                        >
                            <Button variant="success" data-testid="select-file-button">
                                <GlyphIcon iconName="plus" />
                                {' '}
                                Select File
                            </Button>
                        </Uploader>
                        {state.unconfirmedRows && state.unconfirmedRows.some(x => x.action !== undefined) && (
                            <div className="pull-right import-tab__groupBy">
                                <label className="control-label" htmlFor="groupBy">Group by</label>
                                <select
                                    id="groupBy"
                                    className="form-control"
                                    value={groupBy}
                                    onChange={evt => setGroupBy(evt.target.value)}
                                >
                                    <option value="change">type of change</option>
                                    <option value="toc">TOC level</option>
                                </select>
                            </div>
                        )}
                    </div>
                    {state.unExpectedStatusError && (
                        <Alert variant="danger">
                            <p>
                                Something went wrong (
                                {state.unExpectedStatusError.status}
                                :
                                {' '}
                                {state.unExpectedStatusError.statusText}
                                )
                            </p>
                        </Alert>
                    )}
                    {state.csvParsingError && (
                        <Alert variant="danger">
                            <p>The file contains parse errors and could not be uploaded. Please adjust the csv and import it again.</p>
                        </Alert>
                    )}
                    {state.validationErrors && (
                        <>
                            <Alert variant="danger">
                                <p>The following errors were found when uploading the csv. Please adjust the csv and import it again.</p>
                            </Alert>
                            <ImportErrorView errorDetails={state.validationErrors} />
                        </>
                    )}
                    {state.unconfirmedRows && (
                        state.unconfirmedRows.filter(x => x.action !== undefined).length === 0
                            ? <Alert variant="info"><p>This file does not contain any changes.</p></Alert>
                            : (
                                <>
                                    {state.outdated && <Alert variant="warning"><p>Outdated, please reconfirm changes.</p></Alert>}
                                    <ImportConfirmation
                                        groupBy={groupBy}
                                        rows={state.unconfirmedRows}
                                    />
                                    <div className="import-tab__actions">
                                        <Button variant="link" onClick={() => dispatch({ type: IMPORT_CANCELLED })}>CANCEL IMPORT</Button>
                                        <Button variant="primary" onClick={confirmImport}>CONFIRM IMPORT</Button>
                                    </div>
                                </>
                            )
                    )}
                    {state.importConfirmed && (
                        <Alert variant="success">
                            <p>Successfully imported metadata.</p>
                        </Alert>
                    )}
                </Panel.Body>
            </Panel>
        </div>
    );
}

ImportTab.propTypes = {
    moduleId: string.isRequired,
    ngDispatch: func.isRequired,
};

export default ImportTab;
