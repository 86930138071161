import slug from '../../../../../core/slug';
import moduleSettingsTemplate from '../module-settings.html';

class ModulesComponentController {

    get isBasicTab() {
        return this.activeTab === 'basic';
    }

    get isVisualsTab() {
        return this.activeTab === 'visuals';
    }

    get isSubModulesTab() {
        return this.activeTab === 'subModules';
    }

    get isLinkedToSuperModule() {
        if (this.modules) {
            return this.modules.find(module => module.subModules.find(linkedModuleId => linkedModuleId === this.currentModule.id));
        }

        return false;
    }

    // @ngInject
    constructor($scope, ModuleModel, ngDialog, states, lessonSorting, $sce, $document, toaster, UserModel, moduleType) {
        this.ModuleModel = ModuleModel;
        this.ngDialog = ngDialog;
        this.$scope = $scope;
        this.states = states;
        this.lessonSorting = lessonSorting;
        this.$sce = $sce;
        this.$document = $document;
        this.toaster = toaster;
        this.UserModel = UserModel;
        this.moduleType = moduleType;

        $scope.$watch('$ctrl.selectedMethod', () => {
            this.getModules();
        });
    }

    $onInit() {
        this.setActiveTab('basic');
        return this.getExternalPersons();
    }

    getExternalPersons() {
        return this.UserModel
            .queryExternals()
            .then(externals => {
                this.externalPersons = externals;
            });
    }

    clearAllowedExternals() {
        this.currentModule.allowedExternals = [];
    }

    getModules() {
        if (!this.selectedMethod) {
            this.modules = undefined;
            return;
        }

        this.ModuleModel
            .query({
                methodId: this.selectedMethod.id,
                orderBy: 'name',
            })
            .then((modules) => {
                this.modules = modules;
            });
    }

    onMethodChanged() {
        // it also gets new modules from inside the watch function
        this.selectedMethodChanged({
            method: this.selectedMethod,
        });
    }

    onModuleClicked(module) {
        this.selectedModule = module;
    }

    openDetails(module) {
        if (module) this.onModuleClicked(module);

        this.currentModule = module ? module.$copy() : new this.ModuleModel({
            publishingState: 'draft',
            methodId: this.selectedMethod.id,
            codesRequestable: true,
            weight: 0,
            type: this.moduleType.SUPER,
            subModules: [],
            defaultSorting: 'category',
        });

        this.activeTab = 'basic';

        this.setAvailableModules();

        if (this.currentModule.type === this.moduleType.SUB) {
            this.currentModule.superModules = this.modules.filter(superModule =>
                superModule.type === this.moduleType.SUPER && superModule.subModules.includes(this.currentModule.id)
            ).map(superModule => superModule.id);
        }

        this.ngDialog.open({
            template: moduleSettingsTemplate,
            plain: true,
            scope: this.$scope,
            className: 'ngdialog-theme-default ng-dialog__custom-width ngdialog-theme-default--navbar-padded module-settings__modal',
            preCloseCallback: value => {
                if (value) {
                    this.currentModule = null;
                    this.search = undefined;
                }
            },
        });
    }

    createSlug(module) {
        if (module && module.name && !module.slug) {
            module.slug = slug(module.name);
        }
    }

    saveModule(valid) {
        if (!valid) return undefined;

        let index;

        const diff = this.currentModule.$diff();

        const savedSuperModules = this.currentModule.superModules;

        if (diff) {
            diff.forEach(el => {
                if (el.path[0] === 'methodId') {
                    this.modules.forEach((val, key) => {
                        if (val.id === this.currentModule.id) index = key;
                    });
                }
            });
        }

        const isNew = angular.isUndefined(this.currentModule.id);
        delete this.currentModule.hasExerciseEntrance;
        delete this.currentModule.publishingHouseId;

        return this.currentModule
            .$save()
            .then(module => {
                if (isNew) {
                    this.modules.push(module);
                } else {
                    this.selectedModule.$update(module);
                    this.selectedModule.$commit();
                    if (index || index === 0) {
                        this.modules.splice(index, 1);
                    }
                }

                if (module.type === this.moduleType.SUB) {
                    const superModules = this.modules.filter(m => m.type === this.moduleType.SUPER);

                    superModules.forEach(superModule => {
                        switch (savedSuperModules.includes(superModule.id)) {
                            case true:
                                if (!superModule.subModules.includes(module.id)) superModule.subModules.push(module.id);
                                break;
                            default:
                                if (superModule.subModules.includes(module.id)) superModule.subModules = superModule.subModules.filter(subModuleId => subModuleId !== module.id);
                                break;
                        }

                        superModule.$commit();
                    });
                }

                return this.ngDialog.close();
            })
            .catch(err => {
                if (err.status === 409) {
                    this.toaster.pop('warning', err.data.message);
                }
            });
    }

    deleteModule(module) {
        return module.$destroy()
            .then((mod) => {
                const index = this.modules.map((x) => x.id).indexOf(mod.id);
                if (index > -1) this.modules.splice(index, 1);
                return this.ngDialog.close();
            })
            .catch(err => {
                if (err.status === 409) {
                    this.toaster.pop('error', err.data.message);
                }
            });
    }

    setActiveTab(tabName) {
        this.activeTab = tabName;
    }

    handleModuleTypeSwitch(type) {
        if (type === this.moduleType.SUPER) {
            this.currentModule.subModules = [];
            delete this.currentModule.superModules;
        } else {
            delete this.currentModule.subModules;
            this.currentModule.superModules = [];
        }

        this.setAvailableModules();
    }

    setAvailableModules() {
        this.availableModules = this.modules.filter(module => (module.type !== this.currentModule.type) && (module.id !== this.currentModule.id));
    }

    getLinkedModules(module) {
        if (module.type === this.moduleType.SUPER) return module.subModules;

        return module.superModules;
    }

}

export default {
    template: `<div
        class="panel panel-default"
        ng-show="$ctrl.selectedMethod"
>
    <div class="panel-heading">
        <div class="row">
            <h3 class="panel-title col-sm-4 subjects-methods-component__title">Modules</h3>
            <div class="col-sm-8">
                <select id="selectedMethodId"
                        class="form-control form-inline subjects-methods-component__filter"
                        ng-model="$ctrl.selectedMethod"
                        ng-options="method.name for method in $ctrl.methods track by method.id"
                        ng-change="$ctrl.onMethodChanged()">
                </select>
            </div>
        </div>
    </div>
    <div class="panel-body module-list">
        <div class="row module-list__no-result qs-module-list__no-result" ng-show="!$ctrl.modules || $ctrl.modules.length === 0">
            <span class="text-muted">No matching modules found</span>
        </div>
        <div class="row module-list__item qs-module-list__item"
             ng-repeat="(key, module) in $ctrl.modules"
             ng-class="{'module-list__item--active': $ctrl.selectedModule && ($ctrl.selectedModule.id === module.id)}"
        >
            <div class="col-xs-10 module-item__info module-item__col"
                 ng-click="$ctrl.onModuleClicked(module)">
                <div class="col-xs-5 qs-module-item__name">{{module.name}}</div>
                 <div class="col-xs-7 module-labels">
                   <span class="label label-warning qs-module-item__incomplete" ng-if="module.incomplete">Incomplete</span>
                    <publishing-state-component class="pull-right qs-module-item__publishingstate" publishing-state="module.publishingState"
                                                release-date="module.releaseDate"></publishing-state-component>
                </div>
            </div>
            <div class="col-xs-1 module-item__col">
                <span class="label label-info module_weight">
                    {{module.weight}}
                </span>
            </div>
            <div class="col-xs-1 pull-right module-list__actions module-item__col">
                <span class="glyphicon glyphicon-cog subjects-methods__action--edit qs-module__edit"
                  ng-click="$ctrl.openDetails(module)" ></span>
            </div>
        </div>
    </div>
    <div class="panel-footer clearfix">
        <button class="btn btn-success col-xs-12 qs-module__manually" ng-click="$ctrl.openDetails()"><i
                class="glyphicon glyphicon-plus"></i>Add module
        </button>
    </div>
</div>
    `,
    controller: ModulesComponentController,
    bindings: {
        methods: '<',
        selectedMethod: '<',
        selectedMethodChanged: '&',
    },
};
