class MediaLinkCriteria {

    constructor(onCriteriaChanged) {
        this.onCriteriaChanged = onCriteriaChanged;
    }

    setHierarchy(hierarchy) {
        if (hierarchy.method !== this.methodId || hierarchy.module !== this.moduleId) {
            this.methodId = hierarchy.method;
            this.moduleId = hierarchy.module;

            this.nodeId = undefined;

            return this.onCriteriaChanged();
        }
    }

    setContentNode(node) {
        if (node.id !== this.nodeId) {
            this.nodeId = node.id;
            return this.onCriteriaChanged();
        }
    }

    applyTo(target) {
        if (!target) target = {};

        target.moduleId = this.moduleId;
        target.nodeId = this.nodeId || 'general';

        return target;
    }

    toQuery() {
        return this.applyTo({});
    }
}

export default MediaLinkCriteria;
