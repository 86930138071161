import './home.scss';

class ShellHome {
    /* @ngInject */
    constructor(config, tokenAccessorFactory, authService, $window, permissionService) {
        this.title = 'P Content Beheer';
        this.body = '';

        this.config = config;

        this.tokenAccessorFactory = tokenAccessorFactory;
        this.$window = $window;
        this.authService = authService;

        this.permissionService = permissionService;
    }

    viewApiDoc() {
        return this.tokenAccessorFactory.tokenGetter()
            .then(accessToken => {
                this.$window.open(`/api-doc/?access_token=${accessToken}`, '_blank');
            });
    }

    logout() {
        this.authService.logout();
    }
}

export default {
    template: `
    <div ng-if="$ctrl.permissionService.canAccessHome">
        <h1>{{$ctrl.title}}</h1>
        <p>{{$ctrl.body}}</p>

        <div class="row">
            <div class="col-md-4">
                    <div class="col-md-12">
                        <div class="panel panel-default system-info">
                            <div class="panel-heading">
                                <h3 class="panel-title">System info</h3>
                            </div>
                            <div class="panel-body">
                                <div class="system-info__environment"><b>Environment:</b> <span>{{ $ctrl.config.env }}</span></div>
                                <div class="system-info__version"><b>Version number:</b> <span>{{ $ctrl.config.version }}</span></div>
                                <div class="system-info__papertrail"><b>Papertrail:</b> <a href="https://addons-sso.heroku.com/apps/5cfd421b-cf08-4d1a-a5e4-ff3c4efd6464/addons/b36db4fc-b152-4b0f-9da2-7e00c3ed41d1"
                                        target="_blank">View logs.</a></div>
                                <div class="system-info__apidoc"><b>Apidoc:</b> <button class="btn btn-link" ng-click="$ctrl.viewApiDoc()">View doc.</button></div>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="col-md-4">
                <incomplete-modules></incomplete-modules>
            </div>
        </div>
    </div>

    `,
    controller: ShellHome,
};
