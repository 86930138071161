import angular from 'angular';
import './media-links.scss';

import reactComponent from '../../../common/react-component';
import ImportTab from './components/import-tab/ImportTab';

import component from './media-links.view';
import suggestionList from './components/suggestion-list';
import elementScrollOffset from './directives/element-scroll-offset';
import medialinksTabComponent from './components/medialinks-tab.component';

const module = angular.module('app.shell.modules.mediaLinks', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider.state('media-links', {
            url: '/media-links',
            template: `
                <shell-media-links></shell-media-links>
            `,
            security: (permissionService) => permissionService.canManageMediaLinks,
        });
    })
    .component('shellMediaLinks', component)
    .component('suggestionList', suggestionList)
    .component('medialinksTab', medialinksTabComponent)
    .component('medialinkImportTab', reactComponent(ImportTab, ['moduleId']))
    .directive('elementScrollOffset', elementScrollOffset);

export default module.name;
