import moment from 'moment';

const publishingStateLabels = {
    draft: 'label-default',
    published: 'label-success',
    preview: 'label-warning',
    review: 'label-warning',
};

class PublishingStateController {

    get labelText() {
        let label = this.publishingState;

        if (label === 'preview' && this.releaseDate) {
            label = `${label} (${moment(this.releaseDate).format('DD-MM-YYYY')})`;
        }

        return label;
    }

    get labelClass() {
        return publishingStateLabels[this.publishingState];
    }
}

export default {
    template: `<span class="label" ng-class="$ctrl.labelClass">{{$ctrl.labelText}}</span>
    `,
    bindings: {
        publishingState: '<',
        releaseDate: '<',
    },
    controller: PublishingStateController,
};
