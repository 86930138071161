import wordTrainerManagerTemplate from './word-trainer-manager.view.html';

class WordListManagerCtrl {
    // @ngInject
    constructor($scope, $q, WordListModel, WordModel, wordListType, toaster, store, $window, $http, ngDialog) {
        this.$scope = $scope;
        this.$q = $q;
        this.WordListModel = WordListModel;
        this.WordModel = WordModel;
        this.wordListType = wordListType;
        this.toaster = toaster;
        this.store = store;
        this.$window = $window;
        this.$http = $http;
        this.ngDialog = ngDialog;
        this.VIEWS = {
            DETAIL: 'detail',
            MAIN: 'main',
            IMPORT: 'import',
            IMPORT_MEDIA: 'import_media',
        };
    }

    $onInit() {
        this.currentView = this.VIEWS.MAIN;

        this.filteredWordLists = [];
        this.createMode = true;
    }

    showImport() {
        this.importCompleted = false;
        this.currentView = this.VIEWS.IMPORT;
    }

    showImportMedia() {
        this.importCompleted = false;
        this.currentView = this.VIEWS.IMPORT_MEDIA;
    }

    onImportComplete() {
        return this
            .setNode(this.node) // refetch data for current selected node
            .then(() => {
                this.currentView = this.VIEWS.MAIN;
                this.importCompleted = true;
            });
    }

    changeViewMode(view) {
        this.currentView = view;
    }

    onFilterChange(filter) {
        // initial loading
        this.filter = filter;
        this.nodeTree = [];
    }

    setNode(node) {
        this.allSelected = false;
        this.node = node;

        return this.getWordListsForNode(node.id);
    }

    getWordListsForNode(nodeId) {
        return this.WordListModel
            .$get(this.filter.module, nodeId)
            .then(wordLists => {
                this.filteredWordLists = wordLists;

                return this.sortWordLists();
            });
    }

    isPartOfCompilation(wordList) {
        return this.activeCompiledWordList.compilation.find(comp => comp === wordList.id);
    }

    isApplicableToActiveCompiledWordlist(wordList) {

        if (this.activeCompiledWordList && this.activeCompiledWordList.id !== wordList.id) {
            if (this.activeCompiledWordList.nodeId === 'general' && wordList.nodeId !== 'general') {
                return true;
            }

            if (this.activeCompiledWordList.nodeId !== wordList.nodeId && this.activeCompiledWordList.childNodes) {
                return this.activeCompiledWordList.childNodes.find(node => node === wordList.nodeId);
            }
        }

        return false;
    }

    getCompiledWordListsOnCurrentNode() {
        return this.filteredWordLists.filter(wl => wl.type === this.wordListType.COMPILED);
    }

    get selectedWordLists() {
        return this.filteredWordLists ? this.filteredWordLists.filter(m => m.checked) : [];
    }

    sortWordLists() {
        this.filteredWordLists.forEach(wt => {
            if (wt.nodeId === 'general') wt.nodePath = 'Algemeen';
            wt.nodeOrder = this.nodeTree.indexOf(wt.nodeId);
        });

        this.filteredWordLists = this.filteredWordLists.sort(this.byNodeOrderByName);
    }

    onLevelFilterLoaded(rootNodes, selectedNode) {
        // initial loading
        if (!selectedNode) {
            this.rootNode = {
                id: 'general',
                level: 'general',
                path: 'Algemeen',
                nodes: rootNodes,
            };
            selectedNode = this.rootNode;
        }
        this.setNode(selectedNode);

        // build a full tree
        this.nodeTree = [this.rootNode.id];
        this.buildNodeTree(this.nodeTree, this.rootNode);
    }

    buildNodeTree(nodeTree, node) {
        if (node.nodes && node.nodes.length > 0) {
            node.nodes.forEach(x => {
                nodeTree.push(x.id);
                this.buildNodeTree(nodeTree, x);
            });
        }
    }

    byNodeOrderByName(wt1, wt2) {
        if (wt1.nodeOrder > wt2.nodeOrder) {
            return 1;
        } if (wt1.nodeOrder < wt2.nodeOrder) {
            return -1;
        }

        const name1 = wt1.name.toLowerCase();
        const name2 = wt2.name.toLowerCase();
        if (name1 < name2) {
            return -1;
        } if (name1 > name2) {
            return 1;
        }

        // nothing to split them
        return 0;
    }

    toggleSelectAllWordLists() {
        if (this.filteredWordLists) {
            this.filteredWordLists.forEach(wt => {
                wt.checked = this.allSelected;
            });
        }
    }

    addWordList(type) {
        this.currentWordList = this.createDefaultWordList(type);

        this.currentWordList.$commit();
        this.enterDetailView();
    }

    saveWordList() {
        // PUT does not return costly partOf, save
        const { partOf } = this.currentWordList;
        const { childNodes } = this.currentWordList;

        const createMode = !this.currentWordList.id;

        return this.currentWordList
            .$save()
            .then(wordList => {
                if (createMode) {
                    if (wordList.type === this.wordListType.COMPILED) {
                        this.activeCompiledWordList = this.currentWordList;
                    }

                    this.currentWordList.$update(wordList);
                    this.currentWordList.$commit();

                    this.filteredWordLists.push(this.currentWordList);
                } else {
                    const foundWordList = this.filteredWordLists.find(wt => wt.id === wordList.id);

                    if (partOf) wordList.partOf = partOf;
                    if (childNodes) wordList.childNodes = childNodes;

                    foundWordList.$update(wordList);
                    foundWordList.$commit();
                }

                this.toaster.pop('success', 'The Word List was saved succesfully.');
                return this.sortWordLists();
            });
    }

    editWordList(wordList) {
        // GET does not return costly .partOf;
        const { partOf } = wordList;
        const { childNodes } = wordList;

        return this
            .getWordList(wordList)
            .then(wt => {
                if (partOf) wt.partOf = partOf;
                if (childNodes) wt.childNodes = childNodes;

                this.currentWordList = wt;

                return this.enterDetailView();
            });
    }

    deleteWordList(wordList) {
        const removeCompiledFromWordLists = () => this.filteredWordLists
            .forEach(wl => {
                if (wl.partOf) {
                    wl.partOf = wl.partOf.filter(compiled => compiled.id !== wordList.id);
                }
            });

        return wordList.$destroy()
            .then(() => {
                removeCompiledFromWordLists();
            })
            .catch(err => {
                if (!err.status) throw err;

                switch (err.status) {
                    case 404: {
                        this.filteredWordLists = this.filteredWordLists.filter(wl => wl.id !== wordList.id);
                        removeCompiledFromWordLists();
                        break;
                    }
                    case 409: {
                        this.toaster.pop('warning', 'Forbidden', err.data.message);
                        break;
                    }
                    default:
                        throw err;
                }
            });
    }

    removeFromCompilation(wordList) {
        wordList.checked = false;

        if (this.activeCompiledWordList && this.isApplicableToActiveCompiledWordlist(wordList) && this.isPartOfCompilation(wordList)) {
            return this.activeCompiledWordList
                .$removeFromCompilation(wordList.id)
                .then(res => {
                    this.activeCompiledWordList.compilation = res.compilation;
                    this.activeCompiledWordList.$update(this.activeCompiledWordList);
                    this.activeCompiledWordList.$commit();

                    if (wordList.partOf) {
                        wordList.partOf = wordList.partOf.filter(part => part.id !== this.activeCompiledWordList.id);
                    }
                });
        }

        return this.$q.resolve();
    }

    removeSelectedFromCompilation() {
        const selectedLists = this.selectedWordLists;
        this.allSelected = false;

        return selectedLists.reduce((chain, wordList) => chain
            .then(() => this.removeFromCompilation(wordList)), this.$q.resolve());
    }

    deleteSelectedWordLists() {
        const selectedLists = this.selectedWordLists;
        return selectedLists.reduce((chain, wordList) => chain.then(() => this.deleteWordList(wordList)), this.$q.resolve());
    }

    getWordList(wordList) {
        return this.WordListModel
            .get({ id: wordList.id, moduleId: this.filter.module, nodeId: wordList.nodeId });
    }

    enterDetailView() {
        this.currentView = this.VIEWS.DETAIL;
    }

    checkIfWordListFormChanged() {
        if (this.currentWordList.$isDirty()) {
            const paths = (this.currentWordList.$diff() || []).map(d => d.path.join('.'));
            const pathsToIgnore = ['nodePath', 'nodeOrder', 'words', 'partOf', 'childNodes'];

            const changedPaths = paths.filter(path => !pathsToIgnore.find(pathToIgnore => path.match(pathToIgnore)));

            if (changedPaths.length > 0) {
                return true;
            }
        }

        return false;
    }

    showMainView() {
        this.currentView = this.VIEWS.MAIN;
        this.currentWordList = undefined;
    }

    toggleSelectAllFilters() {
        this.currentWordList.filters.wordLists.active = this.allSelectedFilters;
        this.currentWordList.filters.theme.active = this.allSelectedFilters;
        this.currentWordList.filters.wordType.active = this.allSelectedFilters;
        this.currentWordList.filters.number.active = this.allSelectedFilters;
        this.currentWordList.filters.important.active = this.allSelectedFilters;
    }

    createDefaultWordList(type) {
        return new this.WordListModel({
            moduleId: this.filter.module,
            nodeId: this.node.id,
            nodePath: this.node.path,
            type,
            canSwitchLanguage: true,
            canSwitchVoice: true,
            filters: {
                wordLists: {
                    name: 'Woordenlijsten',
                    active: true,
                },
                theme: {
                    name: 'Domeinen',
                    active: true,
                },
                wordType: {
                    name: 'Woordsoorten',
                    active: true,
                },
                number: {
                    name: 'nummer',
                    active: true,
                },
                important: {
                    name: 'belangrijke woorden',
                    active: true,
                },
            },
        });
    }

    addWordListToActiveCompiled(wordList) {
        const activeWordList = this.activeCompiledWordList;
        if (!activeWordList) return this.$q.resolve();

        return activeWordList
            .addToCompilation(wordList)
            .catch(err => {
                if (err.status) {
                    this.toaster.pop('error', `${err.status}: ${err.statusText}`, err.data.message);
                    return;
                }
                throw err;
            });
    }

    addSelectionToActiveCompiled() {
        if (!this.activeCompiledWordList) return this.$q.resolve();

        const selectedLists = this.selectedWordLists;

        return selectedLists
            .reduce((chain, wl) => chain
                .then(() => {
                    const p = this.isApplicableToActiveCompiledWordlist(wl) && !this.isPartOfCompilation(wl)
                        ? this.addWordListToActiveCompiled(wl)
                        : this.$q.resolve();

                    return p.then(() => {
                        delete wl.checked;
                    });
                }), this.$q.resolve())
            .then(() => {
                this.allSelected = false;
            });
    }
}

export default {
    template: wordTrainerManagerTemplate,
    controller: WordListManagerCtrl,
};
