import './toc-selector.component.scss';

class TocSelector {
    // @ngInject
    constructor(TableOfContentModel, MediaLinkModel) {
        this.TableOfContentModel = TableOfContentModel;
        this.MediaLinkModel = MediaLinkModel;
    }

    $onChanges(context) {
        if (context.module && context.module.currentValue) {
            return this.TableOfContentModel
                .$queryNodes(context.module.currentValue)
                .then(nodes => {
                    this.reset();
                    this.tocNodes = nodes;
                    if (this.moduleTocLoaded) this.moduleTocLoaded({ nodes });
                });
        }

        delete this.tocNodes;
        this.reset();
    }

    reset() {
        this.level0Id = undefined;
        this.level0 = undefined;
        this.level1 = undefined;
        this.level2 = undefined;
    }

    onLevel0Change() {
        this.level0 = (!this.level0Id || this.level0Id === 'shelf')
            ? {
                id: this.level0Id || 'general',
                level: 'general',
                name: this.level0Id === 'shelf' ? 'Shelf' : 'Algemeen',
            }
            : (this.tocNodes || []).find(x => x.id === this.level0Id);

        if (this.level0) {
            this.onLevelChange({
                level: 'level0',
                ...this.level0,
            });
        }

        this.level1 = undefined;
        this.level2 = undefined;
    }

    onLevel1Change() {
        const node = {
            ...(this.level1 || this.level0),
            level: this.level1 ? 'level1' : 'level0',
        };

        this.onLevelChange(node);
        this.level2 = undefined;
    }

    onLevel2Change() {
        const node = {
            ...(this.level2 || this.level1),
            level: this.level2 ? 'level2' : 'level1',
        };

        this.onLevelChange(node);
    }

    getPathForNode(node) {
        switch (node.level) {
            case 'level1': {
                return `${this.level0.name} - ${this.level1.name}`;
            }
            case 'level2': {
                return `${this.level0.name} - ${this.level1.name} - ${this.level2.name}`;
            }
            default: {
                return this.level0.name;
            }
        }
    }

    onLevelChange(node) {
        this.levelBeyondMaxDrawerLevel = node.isBeyondMaxDrawerLevel;

        this.levelChanged({
            node: {
                ...node,
                path: this.getPathForNode(node),
            },
        });
    }
}

export default {
    template: `
<div class="panel panel-default toc-selector" ng-if="$ctrl.tocNodes">
    <div class="panel-heading row" ng-if="$ctrl.title || $ctrl.headerMessage">
        <div class="col-xs-4"><h3 ng-if="$ctrl.title" class="panel-title">{{ $ctrl.title }}</h3></div>
        <div class="col-xs-8"><div ng-if="$ctrl.headerMessage" class="qs-header-message pull-right">{{ $ctrl.headerMessage }}</div></div>
    </div>
    <div class="panel-body">
        <select
            id="selectLevel0"
                ng-model="$ctrl.level0Id"
                ng-change="$ctrl.onLevel0Change()"
                class="form-control media-link-level-select"
        >
            <option ng-if="$ctrl.shelf" value="shelf">Shelf</option>
            <option value="">--</option>
            <option ng-repeat="node in $ctrl.tocNodes track by node.id" ng-value="node.id">{{ node.name }}</option>
        </select>

        <select id="selectLevel1" ng-if="$ctrl.level0 && ($ctrl.level0.nodes && $ctrl.level0.nodes.length > 0)"
                ng-model="$ctrl.level1"
                ng-options="node as node.name for node in $ctrl.level0.nodes track by node.id"
                ng-change="$ctrl.onLevel1Change()"
                class="form-control media-link-level-select"
        >
            <option value="">--</option>
        </select>

        <select id="selectLevel2" ng-if="$ctrl.level1 && ($ctrl.level1.nodes && $ctrl.level1.nodes.length > 0)"
                ng-model="$ctrl.level2"
                ng-options="node as node.name for node in $ctrl.level1.nodes track by node.id"
                ng-change="$ctrl.onLevel2Change()"
                class="form-control media-link-level-select"
        >
             <option value="">--</option>
        </select>

    </div>
    <div class="panel-footer background-danger toc-selector--beyond-max-drawer" ng-if="$ctrl.levelBeyondMaxDrawerLevel">
        <span>Selected level is beyond max drawer of the toc !</span>
    </div>
</div>
    `,
    controller: TocSelector,
    bindings: {
        module: '<',
        levelChanged: '&',
        moduleTocLoaded: '&?',
        title: '<?',
        headerMessage: '<?',
        shelf: '<?',
    },
};
