class LanguagePanelController {

    // @ngInject
    constructor($element, $timeout, FileUploader, store, $exceptionHandler, config) {
        this.$element = $element;
        this.$timeout = $timeout;
        this.FileUploader = FileUploader;
        this.store = store;
        this.$exceptionHandler = $exceptionHandler;
        this.config = config;
    }

    $onInit() {
        this.uploader = new this.FileUploader({
            url: `${this.config.baseUrl}/shell/languages/${this.language.id}/versions`,
            autoUpload: true,
            removeAfterUpload: true,
            onBeforeUploadItem: item => {
                const token = this.store.get('p-access_token');

                if (token) {
                    item.headers = {
                        Authorization: `Bearer ${token}`,
                    };
                }
            },
            onCompleteItem: (item, response, status, headers) => {
                if (status === 201) {
                    const location = headers.location;
                    this.lastUploadedVersionId = location.substr(location.lastIndexOf('/') + 1);
                }
            },
        });

        this.uploader.filters.push({
            name: 'jsonFilter',
            fn: item => item.type === 'application/json',
        });
    }

    enterEditMode() {
        this.language.$$editing = true;
        this.$timeout(() => this.$element[0].querySelector('#versionInput').focus());
    }

    save() {
        return this.language
            .$save()
            .then(res => {
                this.clearStateValues();
                this.language.$update(res);
                this.language.$commit();
            })
            .catch(err => {
                if (err.status === 400) {
                    this.language.$$invalid = true;
                    return;
                }
                this.$exceptionHandler(err);
            });
    }

    revert() {
        this.clearStateValues();
        return this.language.$revert();
    }

    clearStateValues() {
        delete this.language.$$editing;
        delete this.language.$$invalid;
    }

    async downloadDefaultTranslation() {
        const response = await this.$http.get(`${this.config.studioUrl}/defaults_${this.language.id}.json`);
        const blob = new Blob([response.data], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        window.URL.revokeObjectURL(url);

        return false;
    }
}

export default {
    template: `
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title" ng-bind="$ctrl.title" />
            </div>
            <div class="panel-body">
                <div class="row">
                    <label ng-attr-for="file-upload-{{$ctrl.language.id}}" class="btn btn-success btn--upload"><span class="glyphicon glyphicon-upload"></span> Upload new version (.json)</label>
                    <input ng-attr-id="file-upload-{{$ctrl.language.id}}" type="file" class="file--input" nv-file-select uploader="$ctrl.uploader" accept='application/json'/>
                    <div ng-if="$ctrl.lastUploadedVersionId" class="alert alert-info">
                        The file you uploaded got version <strong>{{ $ctrl.lastUploadedVersionId }}</strong>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="form-group" ng-class="{ 'has-error': $ctrl.language.$$invalid }">
                        <label for="version" class="control-label">Current version:</label>
                        <div class="input-group">
                            <input type="text" class="form-control qs-version--input" ng-disabled="!$ctrl.language.$$editing" id="versionInput" name="version" ng-model="$ctrl.language.version">
                            <span class="input-group-btn">
                                <button class="btn btn-default qs-version--commit" type="button" ng-if="$ctrl.language.$$editing" ng-click="$ctrl.save()"><span class="glyphicon glyphicon-ok"></span></button>
                                <button class="btn btn-default qs-version--revert" type="button" ng-if="$ctrl.language.$$editing" ng-click="$ctrl.revert()"><span class="glyphicon glyphicon-remove"></span></button>
                                <button class="btn btn-default qs-version--edit" type="button" ng-if="!$ctrl.language.$$editing" ng-click="$ctrl.enterEditMode()"><span class="glyphicon glyphicon-pencil"></span></button>
                            </span>
                        </div>
                        <div class="help-block qs-validation-message" ng-if="$ctrl.language.$$invalid">The version must exist on AWS</div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="btn-group" role="group">
                        <a type="button" class="btn btn-default qs-link--download-defaults" target="_blank" download="defaults_{{ $ctrl.language.id }}.json" ng-click="$ctrl.downloadDefaultTranslation()"><span class="glyphicon glyphicon-download"></span> defaults_{{$ctrl.language.id}}.json</a>
                        <a type="button" class="btn btn-default qs-link--download-current" ng-if="!$ctrl.language.$$editing && $ctrl.language.version" target="_blank" download ng-secure-href="{{ $ctrl.language.currentUrl() }}"><span class="glyphicon glyphicon-download"></span> current</a>
                        <a type="button" class="btn btn-default qs-link--download-latest" target="_blank" download ng-secure-href="{{ $ctrl.language.latestUrl() }}"><span class="glyphicon glyphicon-download"></span> latest</a>
                    </div>
                </div>
            </div>
        </div>
    `,
    controller: LanguagePanelController,
    bindings: {
        title: '@',
        language: '<',
    },
};
