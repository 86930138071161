import Identity from '../../../core/security/identity';
import LocalStorageKeys from '../constants/LocalStorageKeys';

const CookieKeys = {
    p_authenticated: 'p-authenticated',
};

export default class AuthService {

    // @ngInject
    constructor(store, $rootScope, jwtHelper, $window, $cookies, $httpParamSerializer, $state, $document, tokenAccessorFactory, gaTrackerService, config) {
        this.store = store;
        this.$rootScope = $rootScope;
        this.jwtHelper = jwtHelper;
        this.$window = $window;
        this.$cookies = $cookies;
        this.$httpParamSerializer = $httpParamSerializer;
        this.$state = $state;
        this.$document = $document;
        this.tokenAccessorFactory = tokenAccessorFactory;
        this.gaTrackerService = gaTrackerService;
        this.config = config;
    }

    init() {
        if (!this.$cookies.get(CookieKeys.p_authenticated)) {
            this.store.remove(LocalStorageKeys.p_access_token);
            this.store.remove(LocalStorageKeys.p_refresh_token);
            this.$rootScope.identity = Identity.anonymous();
        } else {
            this.setIdentity();
        }
    }

    login(provider, language, returnUrl) {
        const authProvider = provider || 'pelckmans';

        this.store.set(LocalStorageKeys.provider, authProvider);

        const query = this.$httpParamSerializer({
            provider: authProvider,
            language,
            redirect_uri: this.$state.href('auth-callback', {}, { absolute: true }),
            returnUrl,
            register_return_url: this.$state.href('home', {}, { absolute: true }),
        });

        this.$window.location.href = `${this.config.baseUrl}/oauth/authorize?${query}`;
    }

    setIdentity() {
        const accessToken = this.store.get(LocalStorageKeys.p_access_token);
        this.refreshIdentity(accessToken);
    }

    callback(accessToken, refreshToken) {
        this.store.set('p-access_token', accessToken);
        this.store.set('p-refresh_token', refreshToken);

        this.$cookies.put('p-authenticated', Date.now(), this.$rootScope.sameSiteCookieOptions);

        const newIdentity = this._parseIdentity(accessToken);
        this._changeIdentity(newIdentity);

        return this.$rootScope.identity;
    }

    impersonate(pelckmansId) {
        return this.tokenAccessorFactory
            .tokenGetter()
            .then(accessToken => {
                this.store.remove(LocalStorageKeys.p_access_token);
                this.store.remove(LocalStorageKeys.p_refresh_token);
                this.store.remove(LocalStorageKeys.provider);
                this.$cookies.remove(CookieKeys.p_authenticated, this.$rootScope.sameSiteCookieOptions);

                const query = new URLSearchParams({
                    access_token: accessToken,
                    pelckmansId,
                    redirectUrl: `${this.config.studioUrl}/auth-callback`,
                });
                this.$window.location.assign(`${this.config.baseUrl}/oauth/impersonate?${query}`);
            });
    }

    logout() {
        const provider = this.store.get(LocalStorageKeys.provider);

        const query = this.$httpParamSerializer({
            return_url: `${this.$window.location.protocol}//${this.$window.location.host}`,
            provider,
        });

        this.store.remove(LocalStorageKeys.p_access_token);
        this.store.remove(LocalStorageKeys.p_refresh_token);
        this.store.remove(LocalStorageKeys.provider);

        this.$cookies.remove(CookieKeys.p_authenticated, this.$rootScope.sameSiteCookieOptions);

        this.$window.location.href = `${this.config.baseUrl}/oauth/logout?${query}`;
    }

    refreshIdentity(accessToken) {
        this._changeIdentity(this._parseIdentity(accessToken));
    }

    _parseIdentity(accessToken) {
        if (!accessToken) return Identity.anonymous();
        const payload = this.jwtHelper.decodeToken(accessToken);
        return new Identity(payload.sub, payload.givenname, payload.surname, payload.email, payload.roles, payload.author_modules);
    }

    _changeIdentity(identity) {
        this.$rootScope.identity = identity;
        if (identity.isAuthenticated) this.$rootScope.$broadcast('authenticated');
    }
}
