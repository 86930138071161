import merge from 'lodash/merge';
import clone from 'lodash/clone';

import angular from 'angular';

import tocDirtyConfirmationTemplate from './table-of-content-dirty-confirmation.html';
import nodeSettingsTemplate from './node-settings.html';

class TableOfContentController {
    // @ngInject
    constructor(ngDialog, $scope, $state, TableOfContentModel, $sce, toaster, $window) {
        this.tocDirty = false;
        this.ngDialog = ngDialog;
        this.$scope = $scope;
        this.TableOfContentModel = TableOfContentModel;
        this.maxDrawerLevels = [0, 1, 2];
        this.toaster = toaster;
        this.$window = $window;

        $scope.$watch('$ctrl.currentTOC', newValue => {
            this.tocDirty = angular.toJson(newValue) !== this.originalData;
        }, true);

        $scope.$on('$stateChangeStart', (event, toState) => {
            if (this.tocDirty) {
                event.preventDefault();
                return this.askConfirmation()
                    .then(() => {
                        this.tocDirty = false;
                        return $state.go(toState);
                    })
                    .catch(err => err);
            }
        });

        this.nodeRenderer = $sce.trustAsResourceUrl('/shell/modules/table-of-content/node-renderer.html');
    }

    onModulesRetrieved(modules) {
        this.modulesForCurrentMethod = modules;
    }

    onPublishingHouseRetrieved(publishingHouse) {
        this.publishingHouse = publishingHouse;
    }

    getSuperModuleIdFor(moduleId) {
        if (!this.modulesForCurrentMethod) return undefined;

        const module = this.modulesForCurrentMethod.find(mod => mod.id === moduleId);
        if ((module || {}).type === 'SUPER') return moduleId;

        const superModule = this.modulesForCurrentMethod.find(mod => mod.type === 'SUPER' && mod.subModules.includes(moduleId));
        return (superModule || {}).id;
    }

    filterChanged(filter) {
        this.filter = filter;
        this.selectedModule = filter.module || undefined;

        if (this.selectedModule) {
            const superModuleId = this.getSuperModuleIdFor(this.selectedModule);
            const baseUrl = this.publishingHouse && this.publishingHouse.baseUrl ? this.publishingHouse.baseUrl : this.$window.location.origin;

            this.baseLinkUrl = `${baseUrl}/link/${superModuleId}/${this.selectedModule}`;

            return this.TableOfContentModel
                .get({
                    module: this.selectedModule,
                })
                .then(toc => {
                    this.originalData = angular.toJson(toc);
                    this.currentTOC = toc;
                });
        }
    }

    askConfirmation() {
        return this.ngDialog
            .openConfirm({
                template: tocDirtyConfirmationTemplate,
                plain: true,
                scope: this.$scope,
                className: 'ngdialog-theme-default dirty-toc-dialog',
                closeByEscape: false,
                closeByNavigation: false,
                showClose: false,
            });
    }

    nodeSettings(node, action) {
        this.nodeAction = action;

        switch (this.nodeAction) {
            case 'add':
                this.parentNode = node;
                this.node = {
                    displayPrefix: true,
                };
                break;
            case 'edit':
                this.node = clone(node);
                this.currentNode = node;
                break;
            default:
                break;
        }

        return this.ngDialog.open({
            template: nodeSettingsTemplate,
            plain: true,
            scope: this.$scope,
            className: 'ngdialog-theme-default ng-dialog__sm toc__node-settings',
        });
    }

    updateNodeSettings() {
        switch (this.nodeAction) {
            case 'add':
                if (!this.parentNode.nodes) {
                    this.parentNode.nodes = [];
                }

                this.parentNode.nodes.push(this.node);
                this.currentNode = this.node;
                break;
            case 'edit':
                merge(this.currentNode, this.node);
                if (this.currentNode.imageId && !this.node.imageId) delete this.currentNode.imageId;
                break;
            default:
                break;
        }

        this.ngDialog.closeAll();
    }

    getConfirmationMessage(node) {
        let message = `Are you sure you want to delete the node: ${node.title}?`;

        if (node.nodes && node.nodes.length > 0) {
            message += ' It contains child nodes.';
        }

        return message;
    }

    saveTOC() {
        this.currentTOC.module = this.selectedModule;

        return this.currentTOC.$store()
            .then(toc => {
                this.currentTOC.$update(toc);
                this.currentTOC.$commit();

                this.originalData = angular.toJson(this.currentTOC);
                this.tocDirty = false;
            });
    }

    resetTOC() {
        return this.filterChanged(this.filter);
    }

    getFullNodeName(node) {
        return `${node.displayPrefix && node.prefix ? `${node.prefix}: ` : ''}${node.title}`;
    }

    deleteNode(node, el) {
        if (node.medialinkCount > 0) return this.toaster.pop('error', 'Deze node kan niet verwijderd worden.', `De node en/of subnodes bevat(ten) nog ${node.medialinkCount} medialinks.`);
        el.remove();
    }

}

export default {
    template: `<div class="table-of-contents">
    <div class="col-sm-12">
        <form name="tableOfContent" ng-submit="tableOfContent.$valid && $ctrl.saveTOC()">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h3 class="panel-title">Module table of contents</h3>
                </div>
                <div class="panel-body">
                    <div class="form-group col-sm-6">
                        <hierarchy-filter
                            on-modules-retrieved="$ctrl.onModulesRetrieved(modules)"
                            on-publishing-house-retrieved="$ctrl.onPublishingHouseRetrieved(publishingHouse)"
                            levels="4" filter-change="$ctrl.filterChanged(filter)"
                            locked="$ctrl.tocDirty"
                            lock-message="You first need to save or reset the table of content to unlock the filter.">
                        </hierarchy-filter>
                    </div>

                    <div class="clearfix"></div>
                    <hr>

                    <div class="clearfix form-group" ng-if="$ctrl.selectedModule">
                        <div class="btn-group max-drawer-level" uib-dropdown auto-close="always">
                            <button id="simple-btn-keyboard-nav" type="button" class="btn btn-success"
                                    uib-dropdown-toggle>
                                Select max drawer level <span class="caret"></span>
                            </button>
                            <ul uib-dropdown-menu role="menu" aria-labelledby="simple-btn-keyboard-nav">
                                <label ng-repeat="level in $ctrl.maxDrawerLevels">
                                    <input class="{{level}}" type="radio" name="maxDrawerLevel" ng-model="$ctrl.currentTOC.maxDrawerLevel"
                                           ng-value="level"> {{level + 1}}
                                </label>
                            </ul>
                            <export-toc-button disabled="$ctrl.currentTOC.nodes.length === 0" selected-module="$ctrl.selectedModule" base-link-url="$ctrl.baseLinkUrl" />
                        </div>


                        <div class="btn btn-success pull-right add-new-parent" ng-click="$ctrl.nodeSettings($ctrl.currentTOC, 'add')">Add new parent node</div>
                    </div>

                    <div class="please-select-module-message text-muted" ng-if="!$ctrl.selectedModule">Please use the hierarchy filter above to select a module first before you can see/edit the table of content.</div>

                    <div ng-if="$ctrl.selectedModule">
                        <div ui-tree id="tree-root" data-max-depth="6">
                            <ol ui-tree-nodes ng-model="$ctrl.currentTOC.nodes" ng-init="level = 0" class="table-of-content__list">
                                <li ng-repeat="node in $ctrl.currentTOC.nodes" class="clearfix table-of-content__list-item" ui-tree-node
                                >
                                    <span ng-include="$ctrl.nodeRenderer"></span>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div
                    class="panel-footer"
                    ng-if="$ctrl.selectedModule"
                >
                    <button type="submit" class="btn btn-success save-toc-btn" ng-disabled="!$ctrl.tocDirty">Save</button>

                    <div class="btn btn-default reset-toc-btn"
                         ng-if="$ctrl.tocDirty" ng-click="$ctrl.resetTOC()">Reset
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
`,
    controller: TableOfContentController,
};
