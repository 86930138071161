import DeepDiff from 'deep-diff';

/* @ngInject */
export default function LanguageFactory($modelFactory) {
    const model = $modelFactory('shell/languages', {
        actions: {
            query: {
                afterRequest: res => res.data,
            },
            update: {
                beforeRequest: req => {
                    if (!req.data.version) delete req.data.version;
                },
            },
        },
        instance: {
            publishingHouseName: function () { //eslint-disable-line object-shorthand
                return this.id === 'fr'
                    ? 'Enseignement secondaire'
                    : 'Secundair onderwijs';
            },
            currentUrl: function () { //eslint-disable-line object-shorthand
                return `/api/shell/languages/${this.id}/versions/${this.version}`;
            },
            latestUrl: function () { //eslint-disable-line object-shorthand
                return `/api/shell/languages/${this.id}/versions/latest`;
            },
            $revert: function () { // eslint-disable-line object-shorthand
                const changes = this.$diff();
                if (changes && changes.length > 0) {
                    changes.forEach(change => DeepDiff.revertChange(this, {}, change));
                }
            },
        },
    });

    return model;
}
