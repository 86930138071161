/* @ngInject */
export default function WordList($modelFactory) {
    const model = $modelFactory('shell/modules/{moduleId}/table-of-content/{nodeId}/word-lists', {
        actions: {
            post: {
                beforeRequest: request => {
                    if (request.data.words) delete request.data.words;
                },
            },
        },
        $get: function (moduleId, nodeId) { // eslint-disable-line object-shorthand
            return model.$call({
                method: 'GET',
                isArray: true,
                wrap: true,
                url: `/api/shell/modules/${moduleId}/table-of-content/${nodeId}/word-lists`,
                afterRequest: response =>
                    // map inner methods array
                    response.data,
            });
        },
        $getList: function (moduleId, nodeId) { // eslint-disable-line object-shorthand
            return model.$call({
                method: 'GET',
                isArray: true,
                wrap: true,
                url: `/api/shell/modules/${moduleId}/table-of-content/${nodeId}/word-lists`,
                afterRequest: response =>
                    // map inner methods array
                    response.data,
            });
        },
        $getSuggestions: function (moduleId, nodeId) { // eslint-disable-line object-shorthand

            return model.$call({
                method: 'GET',
                isArray: true,
                wrap: false,
                url: `/api/shell/modules/${moduleId}/table-of-content/${nodeId}/word-lists/medialinks/suggestions`,
                afterRequest: response =>
                    // map inner methods array
                    response.data,
            });
        },
        instance: {
            addToCompilation(wordList) {
                return model
                    .$call({
                        method: 'POST',
                        url: `/api/shell/modules/${this.moduleId}/table-of-content/${this.nodeId}/word-lists/${this.id}/compilation/${wordList.id}`,
                        wrap: false,
                        afterRequest: res => {
                            this.compilation = res.compilation;
                            this.$commit();

                            if (!wordList.partOf) wordList.partOf = [];

                            if (!wordList.partOf.find(p => p.id === this.id)) {
                                const { id, name } = this;
                                wordList.partOf.push({
                                    id,
                                    name,
                                });

                                wordList.$commit();
                            }
                        },
                    });
            },
            $removeFromCompilation: function (wordListToRemoveId) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'DELETE',
                    url: `/api/shell/modules/${this.moduleId}/table-of-content/${this.nodeId}/word-lists/${this.id}/compilation/${wordListToRemoveId}`,
                    data: this,
                    wrap: false,
                });
            },
        },
    });
    return model;
}
